import store from '@/store';
import axios from 'axios'
import { ElMessage } from 'element-plus'
import router from '../router'

// 创建axios实例
const request = axios.create({
    baseURL: '/proxy_url',
    // baseURL: 'https://api-guanzhao.mingz.net.cn',
     // baseURL: 'http://guanzhao-api.com',
    // 超时
    timeout: 60000 * 10,
    headers: {'Content-Type': 'application/json;charset=utf-8'}
})

// 添加请求拦截器
request.interceptors.request.use(function (config) {
    // 在发送请求之前做些什么
    if (store.state.user.token) {
      //'Bearer '
      config.headers['token'] = store.state.user.token // 让每个请求携带自定义token 请根据实际情况自行修改
    }

    // 过滤请求参数里面的空值
    if(config.params !=undefined){
        const filterParam = {}
        Object.keys(config.params).forEach(function (item, index) {
            if(config.params[item]){
                filterParam[item] = config.params[item]
            }
        })
        config.params = filterParam
    }

    return config;
  }, function (error) {
    // 对请求错误做些什么
    return Promise.reject(error);
  });

request.interceptors.response.use(function (res) {
  if(res.status === 200) {
    // if(res.data.code === 401){
    //   router.push({name: "login"})
    // }else{
    //   return Promise.resolve(res)
    // }
    return Promise.resolve(res)
  } else {
    res.data.msg && ElMessage.warning(res.data.msg || '请求失败！')
    return Promise.reject(res)
  }
}, function (error) {
  return Promise.reject(error);
})

export default request
